@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 8%;

    --foreground: 105 0% 100%;

    --card: 0 0% 8%;
    --card-foreground: 138 5% 95%;

    --popover: 0 0% 8%;
    --popover-foreground: 138 5% 95%;

    --primary: 142 67% 58%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 0 0% 11%;
    --secondary-foreground: 138 5% 95%;

    --muted: 0 0% 11%;
    --muted-foreground: 105 3% 46%;

    --accent: 0 0% 11%;
    --accent-foreground: 138 5% 95%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 138 5% 95%;

    --border: 0 0% 37%;

    --input: 0 0% 37%;

    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }

  h1 {
    @apply text-6xl md:text-7xl lg:text-[120px] leading-[60px] md:leading-[72px] lg:leading-[120px];
  }

  h2 {
    @apply text-[42px] md:text-5xl lg:text-[52px] leading-[46px] lg:leading-[56px];
  }

  h3 {
    @apply text-2xl md:text-3xl lg:text-[44px];
  }

  h4 {
    @apply text-xl;
  }

  h5 {
    @apply text-lg;
  }
}

*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.input-error {
  display: none;
}

input:invalid ~ .input-error {
  display: block;
}
